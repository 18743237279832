/**
 * jQuery
 */
let $ = require('jquery');

/**
 * Popper
 */
require('popper.js');

/**
 * Bootstrap
 */
require('bootstrap');

/**
 * Masonry
 */
let Masonry = require('masonry-layout');
let jQueryBridget = require('jquery-bridget');
jQueryBridget('masonry', Masonry, $);

let imagesLoaded = require('imagesloaded');
imagesLoaded.makeJQueryPlugin($);

//prevent right click to protect images for download
window.addEventListener(
	'contextmenu',
	function (e) {
		e.preventDefault();
	},
	false
);

setTimeout(function () {
	let $grid = $('#masonry').masonry({
		itemSelector: '.grid-item',
		columnWidth: '.grid-sizer',
		gutter: '.gutter-sizer',
		percentPosition: true,
	});

	$grid.find('[loading="lazy"]').on('load', function () {
		$grid.masonry('layout');
	});

	// $grid.imagesLoaded().progress(function () {
	// 	$grid.masonry('layout');
	// });
}, 200);
